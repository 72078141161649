import { useStaticQuery, graphql } from "gatsby";

const useData = () => {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson(sort: { fields: title, order: DESC }) {
        nodes {
          id
          title
          imageFilePath
        }
      }
      allFile(filter: { relativeDirectory: { eq: "dental-services" } }) {
        nodes {
          name
          childImageSharp {
            fixed(width: 1000, height: 500, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  `);

  const findImage = (files, path) => {
    if (!files) return;
    return files.find((file) => path.includes(file.name))?.childImageSharp
      .fixed;
  };

  return data.allServicesJson.nodes.map((item) => ({
    id: item.id,
    title: item.title,
    image: findImage(data.allFile.nodes, item.imageFilePath),
  }));
};

export default useData;
