type Data = Array<{
  id: string;
  svg: string;
  title: string;
  contents: string;
  link: string;
  internalRoute: boolean;
}>;

const data: Data = [
  {
    id: "newPatient",
    svg: "RegistrationSVG",
    title: "Start The Process",
    contents:
      "New patient can get the patient registration form through this link",
    link: "/new-patient",
    internalRoute: true,
  },
  {
    id: "referralForm",
    svg: "LetterSVG",
    title: "Referral Form",
    contents: "Paperless online referral form for other doctors",
    link: "/referral",
    internalRoute: true,
  },
  {
    id: "contactUs",
    svg: "ContactSVG",
    title: "Contact Us",
    contents: "Contact us for general enquiry and your appointment",
    link: "/contact",
    internalRoute: true,
  },
];

export default data;
