import React from "react";
import Img from "gatsby-image";
import data from "./data";
import { RegistrationSVG, ContactSVG, LetterSVG } from "./svg";

import {
  StyledCard,
  SvgWrapper,
  Title,
  Contents,
  StyledLink,
  ContentsWrapper,
} from "./styles";

const Card = ({ title, contents, link, svg }) => {
  return (
    <StyledCard>
      <StyledLink to={link}>
        <SvgWrapper>
          {svg === "RegistrationSVG" ? (
            <RegistrationSVG />
          ) : svg === "LetterSVG" ? (
            <LetterSVG />
          ) : (
            <ContactSVG />
          )}
        </SvgWrapper>
        <ContentsWrapper>
          <Title>{title}</Title>
          <Contents>{contents}</Contents>
        </ContentsWrapper>
      </StyledLink>
    </StyledCard>
  );
};

export default Card;
