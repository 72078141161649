import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ContentsWrapper, Title, Contents, StyledLink } from "./styles";
import banner from "../../images/001_Gippsland.png";
import BannerImage from "../BannerImg";

const SubTitle = () => {
    const image = useStaticQuery(graphql`
        fragment ImageSubTitle on File {
            childImageSharp {
                fluid(
                    maxWidth: 1900
                    maxHeight: 850
                    grayscale: false
                    quality: 100
                ) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        query {
            thumbnail_1: file(relativePath: { eq: "001_Gippsland.png" }) {
                ...ImageSubTitle
            }
        }
    `);
    return (
        <>
            <StyledLink to="/new-patient"><Title>Patient information</Title></StyledLink>
            <ContentsWrapper>
                <Contents>
                    A warm welcome to Gippsland Prosthodontics where your
                    concerns are the reason for us being in practice. We are
                    here to listen and help you through the process. We work in
                    conjunction with your dentist but a referral is not
                    necessary to see us. Click on start the process on the link or icon above to request a
                    booking or simply ring or message us and one of our staff will
                    contact you shortly.
                </Contents>
            </ContentsWrapper>

            <BannerImage imagesrc={banner}></BannerImage>
        </>
    );
};

export default SubTitle;
