import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { breakpoints } from "../../styles/variables";
import { colors } from "../../styles/variables";
import Modal from "styled-react-modal";
import { FaPlay } from "react-icons/fa";

export const Wrapper = styled.div`
  position: relative;
  height: auto;
  @media (min-width: ${breakpoints.sm}) {
    margin: 0 0 2rem 0;
  }
`;

export const SliderWrapper = styled.div`
  position: relative;
`;
export const StyledImg = styled(Img)`
  min-height: 600px;
  min-width: 1200px;
  transition: all 0.5s ease-in-out;
  position: relative;
  transform: scale(1.1);
  animation: float linear 16s infinite;
  @keyframes float {
    0% {
      left: -600px;
    }
    50% {
      left: -400px;
    }
    100% {
      left: -600px;
    }
  }
  @media (min-width: ${breakpoints.sm}) {
    min-width: 1800px;
    animation: float linear 16s infinite;
    @keyframes float {
      0% {
        left: -500px;
      }
      50% {
        left: -300px;
      }
      100% {
        left: -500px;
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    animation: float linear 16s infinite;
    @keyframes float {
      0% {
        left: -80px;
      }
      50% {
        left: 80px;
      }
      100% {
        left: -80px;
      }
    }
  }
`;

export const StyledButton = styled.button`
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  outline: none;
  border: solid 2px #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background-color: transparent;
  transition: border-color 0.3s ease, color 0.3s ease;

  &:hover {
    border-color: ${colors.primary};
    svg {
      color: ${colors.primary};
    }
  }
  &:focus {
    outline: none;
  }
  font-size: 3rem;
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SlideTextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  top: 55%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
export const Since = styled.p`
  font-size: 1.25rem;
  color: #fff;
  margin: 1rem auto 1.5rem auto;
  width: 7rem;
  @media (min-width: ${breakpoints.sm}) {
    width: 8rem;
    font-size: 1.5rem;
    margin: 1.5rem auto 2rem auto;
  }
`;
export const HeroTitle = styled.h1`
  margin: 0 1rem;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  width: 20rem;
  opacity: 0.95;
  text-align: center;
  text-shadow: 2px 2px 300px rgb(0 0 0 / 99%);

  @media (min-width: ${breakpoints.sm}) {
    width: 20rem;
    font-size: 2rem;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 30rem;
    font-size: 2.5rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: 40rem;
    font-size: 3rem;
    padding: 1.25rem 0 0 0;
  }
  @media (min-width: 1300px) {
    font-size: 3rem;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }
`;

export const StyledIcon = styled(FaPlay)`
  font-size: 1.7rem;
  color: #fff;
`;

export const StyledModal = Modal.styled`
    width: 80vw;
    height: 80vh;
    transition: opacity ease 500ms;
`;

export const SlideWrapper = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
	width: 100%;
    margin 
`;

export const Button = styled(Link)`
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.8rem 1.5rem 0.9rem 1.5rem;
  border-radius: 3px;
  font-size: 1rem;
  height: 3rem;
  &:hover {
    color: #fff;
    background-color: ${colors.lightBlue};
    border-color: ${colors.lightBlue};
  }

  text-align: center;

  background-color: ${colors.primary};
  border: solid 1px ${colors.primary};
  cursor: pointer;
  width: 15rem;
  margin: 0 auto;
  display: block;
`;
export const TitleContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 auto;
  padding: 3rem 1rem 3rem 1rem;
  max-width: 1200px;
  text-align: center;
  letter-spacing: 1px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  bottom: unset;
  left: 0;
  transform: translate(0, 0);
  @media (min-width: ${breakpoints.lg}) {
    font-size: 2rem;
    margin-top: -32px;
    z-index: 1;
    padding: 2rem 1rem;
  }
`;
export const Title = styled.h1`
  color: ${colors.secondary};
  margin: 0 auto;
  font-size: 1.125rem;
  font-weight: 500;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.75rem;
  }
  @media (min-width: ${breakpoints.lg}) {
    font-size: 2.125rem;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 80%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }
`;
export const Details = styled.p`
  margin-top: 1.5rem;
  font-size: 1.2rem;
  font-weight: 300;
  @media (min-width: ${breakpoints.lg}) {
    text-align: center;
    margin-top: 3rem;
  }
`;
