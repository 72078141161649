import React, { useEffect, useState } from "react";

const usePopup = () => {
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const localStorageOpenPopup = localStorage.getItem("openPopup")
      ? JSON.parse(localStorage.getItem("openPopup"))
      : true;
    setOpenPopup(!!localStorageOpenPopup);
  }, []);

  const hidePopup = () => localStorage.setItem("openPopup", "false");

  return { openPopup, hidePopup };
};

export default usePopup;
