import React from "react";
import {
  ContentsWrapper,
  Title,
  Contents,
  StyledAnchor,
  StyledHandsAcrossTheWarterSVG,
} from "./styles";

const Support = () => {
  return (
    <>
      <Title>We proudly support local</Title>

      <ContentsWrapper>
        <StyledAnchor href="https://www.handsacrossthewater.org.au/fundraisers/RajHillary/doing-good-rewards-ride-2023">
          <StyledHandsAcrossTheWarterSVG />
          <Contents>RAJ HILLARY</Contents>
        </StyledAnchor>
      </ContentsWrapper>
    </>
  );
};

export default Support;
