import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Slider from "react-slick";

import {
  Wrapper,
  StyledImg,
  HeroTitle,
  SlideWrapper,
  SlideTextContainer,
  Button,
  Since,
  TitleContainer,
  Title,
} from "./styles";

const slideSettings = {
  arrows: false,
  pauseOnHover: false,
  dots: false,
  fade: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000,
};

const HeroVideo = () => {
  const image = useStaticQuery(graphql`
    fragment ImageFragment on File {
      childImageSharp {
        fluid(maxWidth: 3000, maxHeight: 1300, grayscale: false, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      thumbnail_1: file(relativePath: { eq: "hero/003_Gippsland.png" }) {
        ...ImageFragment
      }
      thumbnail_2: file(relativePath: { eq: "hero/007_Gippsland.png" }) {
        ...ImageFragment
      }
      thumbnail_3: file(relativePath: { eq: "hero/GPmain3.png" }) {
        ...ImageFragment
      }
      thumbnail_4: file(relativePath: { eq: "hero/049_Gippsland.png" }) {
        ...ImageFragment
      }
      thumbnail_5: file(relativePath: { eq: "hero/GPmain5.png" }) {
        ...ImageFragment
      }
      thumbnail_6: file(relativePath: { eq: "hero/GPmain6.png" }) {
        ...ImageFragment
      }
    }
  `);

  return (
    <Wrapper>
      <SlideWrapper>
        <Slider {...slideSettings}>
          <StyledImg
            fluid={image.thumbnail_1.childImageSharp.fluid}
            alt="Gippsland prosthodontics"
          />
          <StyledImg
            fluid={image.thumbnail_2.childImageSharp.fluid}
            alt="Gippsland prosthodontics"
          />
          <StyledImg
            fluid={image.thumbnail_3.childImageSharp.fluid}
            alt="Gippsland prosthodontics"
          />
          <StyledImg
            fluid={image.thumbnail_4.childImageSharp.fluid}
            alt="Gippsland prosthodontics"
          />
          <StyledImg
            fluid={image.thumbnail_5.childImageSharp.fluid}
            alt="Gippsland prosthodontics"
          />
          <StyledImg
            fluid={image.thumbnail_6.childImageSharp.fluid}
            alt="Gippsland prosthodontics"
          />
        </Slider>
      </SlideWrapper>
      <TitleContainer>
        <Title>
          Providing healthy, functional and natural looking smiles since 2006…
        </Title>
      </TitleContainer>
    </Wrapper>
  );
};

export default HeroVideo;
