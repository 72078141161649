import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";
import BackgroundImage from "gatsby-background-image";

export const ContentsWrapper = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
`;
export const Title = styled.h1`
  text-align: center;
  text-deco
`;
export const StyledLink = styled(Link)`
  color: ${colors.dark};
  text-decoration: none;
  text-align: center;
  &:hover {
    color: ${colors.primary};
  }
`;
export const Contents = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1.375rem;
  @media (min-width: ${breakpoints.md}) {
    width: 80%;
    font-size: 1.75rem;
  }
`;
export const WrapperBackgroundImage = styled(BackgroundImage)`
  position: relative;
  height: 500px;
  background-size: cover !important;
  background-position: center;
  background-attachment: fixed;
`;
export const PlaceHolder = styled.div`
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
`;
