import React from "react";
import data from "./data";
import Card from "./Card";
import { Wrapper, MainTitle } from "./styles";

export const DigitalProcess = () => {
  return (
    <>
      {/* <MainTitle>Our Digital Process</MainTitle> */}
      <Wrapper>
        {data.map((item) => (
          <Card key={item.id} {...item} />
        ))}
      </Wrapper>
    </>
  );
};
