import React, { useEffect } from "react";
import Instafeed from 'instafeed.js';
import styled, { css } from "styled-components";
import { breakpoints, colors } from "../../styles/variables";


const InstaContainer = styled.div`
    padding: 8rem 0 1rem;
    #instafeed {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        max-width: 80%;
        margin: 6rem auto;
        @media(max-width: ${breakpoints.lg}){
        grid-column-gap: 0.5rem;
        grid-row-gap: 0.5rem;
        max-width: 95%;
        margin: 3rem auto;
        }
    }

    #instafeed a {
        display: block;
        // flex-direction: column;
        // justify-content: center;
        position: relative;
        // transition: 1.5s ease-in-out;
        &:hover {
        z-index: 3;
        // transform: scale(1.25);
        }
    }

    #instafeed img {
        display: block;
        max-width: 100%;
    }

    #instafeed .caption-box {
        position: absolute;
        top: 0;
        color: transparent;
        font-size: 0.8rem;
        letter-spacing: 0.08rem;
        height: 100%;
        width: 100%;
        padding: 2rem;
    }
    #instafeed:hover .caption-box:hover{
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.4);
    }
    #instafeed .caption {
        display: block;
        font-size: 0.8rem;
        letter-spacing: 0.08rem;
        height: 100%;
        overflow: hidden;
    }
`;
const Title = styled.h2`
    color: ${colors.dark};
    text-align: center;
    font-size: 2.875rem;
    font-weight: 300;
    margin: 0 2rem;
    @media (max-width: ${breakpoints.lg}) {
        font-size: 2.625rem;
    }
    @media (max-width: ${breakpoints.md}) {
        font-size: 2.25rem;
    }
    @media (max-width: ${breakpoints.sm}) {
        font-size: 1.25rem;
    }
`
const TitleSpan = styled.a`
    color: ${colors.primary};
    display: block;
    text-align: center;
    font-size: 2.875rem;
    font-weight: 300;
    margin: 0 auto;
    text-decoration: none;
    &:hover {
        color: ${colors.dentBlue};
    }
    @media (max-width: ${breakpoints.lg}) {
        font-size: 2.625rem;
    }
    @media (max-width: ${breakpoints.md}) {
        font-size: 2.25rem;
    }
    @media (max-width: ${breakpoints.sm}) {
        font-size: 1.25rem;
    }
`
const Insta = () => {
    return (
        <InstaContainer>
            <Title>
                Follow us from Instagram
            </Title>
            <TitleSpan href="https://www.instagram.com/gippsland_prosthodontics/?hl=en"
                target="_blank">@gippsland_prosthodontics</TitleSpan>
            {/* <div id="instafeed" /> */}
        </InstaContainer>
    );
};

export default Insta;
