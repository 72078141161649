import React from "react";
import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
`;

export default Flex;
