import React from "react";
import Slider from "react-slick";
import useData from "./useData";
import { Wrapper, SliderWrapper, Left, Right } from "./styles";
import Slide from "./Slide";

const LineupSection = () => {
  const data = useData();
  const slideSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnHover: true,
    centerMode: true,
    centerPadding: "0px",
    cssEase: "ease-in-out",
    focusOnSelect: true,
    prevArrow: <Left />,
    nextArrow: <Right />,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <SliderWrapper>
        <Slider {...slideSettings}>
          {data.map((item) => (
            <Slide key={item.id} {...item} />
          ))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  );
};

export default LineupSection;
