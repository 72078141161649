import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";
import { HandsAcrossTheWarterSVG } from "./svg";

export const ContentsWrapper = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h1`
  text-align: center;
  text-deco
`;

export const Contents = styled.p`
  width: 100%;
  text-align: left;
  font-size: 1rem;
  padding-left: 54px;
`;

export const StyledAnchor = styled.a`
  font-size: 1rem;
  color: ${colors.dentBlue};
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 2.875rem;
  font-weight: 300;
  margin: 0 auto;
  &:hover {
    color: ${colors.primary};
  }
  @media (max-width: ${breakpoints.lg}) {
    font-size: 2.625rem;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 2.25rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: 1.25rem;
  }
`;

export const StyledHandsAcrossTheWarterSVG = styled(HandsAcrossTheWarterSVG)`
  width: 250px;
`;
