import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { DigitalProcess } from "../components/digital-process";
import SubTitle from "../components/sub-title";
import HeroVideo from "../components/hero-video";
import LineupSection from "../components/lineup-section";
import Insta from "../components/insta";
import { ModalPopup, PopupContents } from "../components/PopupContents";
import usePopup from "../hooks/usePopup";
import Support from "../components/support";

const IndexPage = () => {
  const { openPopup } = usePopup();

  return (
    <Layout>
      <SEO
        title="Gippsland dentistry"
        description="Gippsland prosthodonics, dentistry. providing dental services - dental implants, bridge, crowns, onlays, Inlays, Venners, Laser Dentistry, TMD and dentures"
      />
      <HeroVideo />
      <DigitalProcess />
      <SubTitle />
      <Insta />
      {!openPopup && <LineupSection />}
      {openPopup && (
        <ModalPopup trigger={<LineupSection />} modal nested defaultOpen>
          {(close) => <PopupContents close={close} />}
        </ModalPopup>
      )}
      <Support />
    </Layout>
  );
};

export default IndexPage;
