import React from "react";
import styled, { css } from "styled-components";
import { breakpoints } from "../../styles/variables";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export const Wrapper = styled.div`
  padding: 10rem 0 7rem;
`;

export const SlideDrop = styled.div`
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.5s;
`;

export const MovieTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
  opacity: 1;
  transition: opacity 0.5s;
  text-align: center;
  text-transform: uppercase;
  @media (min-width: ${breakpoints.sm}) {
    font-size: 2rem;
  }
`;

export const SlideWrapper = styled.div`
  position: relative;
  opacity: 1;
  transition: transform 0.5s ease;
  cursor: pointer;
`;

const ArrowStyle = css`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  transition: transform 0.4s;
  z-index: 1;
  &:hover {
    transform: translate(0, -50%) scale(1.2);
  }
  @media (min-width: ${breakpoints.sm}) {
    font-size: 70px;
  }
`;

export const ArrowLeft = styled(BsChevronLeft)`
  ${ArrowStyle}
  left: 10px;
  @media (min-width: ${breakpoints.sm}) {
    left: 50px;
  }
`;

export const ArrowRight = styled(BsChevronRight)`
  ${ArrowStyle}
  right: 10px;
  @media (min-width: ${breakpoints.sm}) {
    right: 50px;
  }
`;

export const SliderWrapper = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    width: 100% !important;
  }

  img {
    transition: transform 0.6s ease !important;
  }

  .slick-slide,
  .gatsby-image-wrapper {
    height: 400px !important;
    @media (min-width: ${breakpoints.sm}) {
      height: 500px !important;
    }
  }

  .slick-slide:hover img {
    transform: scale(1.1);
  }

  .slick-slide:hover ${MovieTitle} {
    opacity: 1;
  }

  .slick-slide:hover ${SlideDrop} {
    opacity: 0;
  }
`;

export const Left = ({ onClick }: { onClick?: () => void }) => (
  <ArrowLeft onClick={onClick} />
);
export const Right = ({ onClick }: { onClick?: () => void }) => (
  <ArrowRight onClick={onClick} />
);
