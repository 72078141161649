import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { SlideWrapper, SlideDrop, MovieTitle } from "./styles";

const Slide = ({ id, image, title }) => (
  <SlideWrapper>
    <Link to={`/services/${id}`}>
      <Img fixed={image} />
      <SlideDrop>
        <MovieTitle>{title}</MovieTitle>
      </SlideDrop>
    </Link>
  </SlideWrapper>
);

export default Slide;
