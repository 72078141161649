import React from "react";

export const HandsAcrossTheWarterSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 234.84 58.74"
      fill="#231f20"
      width="250px"
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Layer_2-2" data-name="Layer 2">
            <g id="Layer_1-2-2" data-name="Layer 1-2">
              <path
                class="cls-1"
                d="M41.13,13.51a2.27,2.27,0,0,0-2.62,1.85l-4.4,25.58c-1.43,8.25-7.85,13.81-15.29,13.17A12.57,12.57,0,0,1,9,48.23a11.35,11.35,0,0,0,4.38.85,12,12,0,0,0,10.9-7.15c4.5-1.88,7.53-6.43,8.48-13.08l3.06-23a2.27,2.27,0,0,0-4.49-.6l-3.06,23a14.86,14.86,0,0,1-2.86,7.37L28,2.56A2.27,2.27,0,0,0,23.51,2a1.64,1.64,0,0,0,0,.22L20.62,38.26c-2.7.31-4.68.05-7.53-2.66,4.45-.69,6.45-4.42,6.47-8.39l-.27-18.6A2.28,2.28,0,0,0,17,6.38h0a2.27,2.27,0,0,0-2.23,2.3L15,27.22c0,.93-.21,3.16-2,3.73a3.15,3.15,0,0,1-4.12-1.48L4.3,20.27A2.31,2.31,0,0,0,1.56,19,2.27,2.27,0,0,0,0,21.38L1.29,38.92c1,11.25,8,19.82,18.64,19.82,9.19,0,16.91-6.94,18.65-17L43,16.11a2.28,2.28,0,0,0-1.85-2.62M18,42.85a7.15,7.15,0,0,1-5.3,1.67C7.92,44,6.06,40.4,5.64,33,8.69,38.76,13,42.55,18,42.85"
              />
              <path
                class="cls-1"
                d="M49.41,49.93l6-13.34a.38.38,0,0,1,.34-.22H56a.38.38,0,0,1,.34.22l6,13.34a.36.36,0,0,1-.13.48.32.32,0,0,1-.21,0h-.79a.35.35,0,0,1-.34-.22L59.41,47h-7L51,50.23a.35.35,0,0,1-.34.22h-.79a.36.36,0,0,1-.46-.19A.39.39,0,0,1,49.41,49.93Zm9.42-4.2c-1-2.16-1.92-4.34-2.89-6.5h-.16l-2.89,6.5Z"
              />
              <path
                class="cls-1"
                d="M70.52,36.37a7,7,0,0,1,4.8,1.84.38.38,0,0,1,0,.54h0l-.54.54c-.16.2-.32.18-.52,0a6,6,0,0,0-3.79-1.49,5.7,5.7,0,1,0,3.79,9.91.38.38,0,0,1,.48-.06l.59.54a.38.38,0,0,1,0,.54h0a6.73,6.73,0,0,1-4.82,1.92A7.14,7.14,0,0,1,70,36.39h.54Z"
              />
              <path
                class="cls-1"
                d="M78.38,37a.38.38,0,0,1,.38-.38h5.13a4.26,4.26,0,0,1,4.32,4.2v0a4.42,4.42,0,0,1-2.93,4l2.72,5a.4.4,0,0,1-.12.53.34.34,0,0,1-.22,0h-1a.33.33,0,0,1-.32-.18L83.7,44.94H79.91v5.17a.39.39,0,0,1-.38.38h-.77a.38.38,0,0,1-.38-.38Zm5.41,6.76a2.85,2.85,0,0,0,0-5.69H80v5.69Z"
              />
              <path
                class="cls-1"
                d="M97.58,36.37a7.14,7.14,0,1,1-7.12,7.16,7.14,7.14,0,0,1,7.12-7.16Zm0,12.88a5.75,5.75,0,1,0-5.73-5.77v0a5.75,5.75,0,0,0,5.73,5.74Z"
              />
              <path
                class="cls-1"
                d="M106.86,48.36l.3-.38c.2-.26.42-.42.69-.18a5.42,5.42,0,0,0,3.35,1.51,2.38,2.38,0,0,0,2.64-2.11s0-.06,0-.09c0-1.37-1.19-2.18-3.47-3.13s-3.49-1.84-3.49-4.1c0-1.35,1.07-3.53,4.22-3.53a6.57,6.57,0,0,1,3.39,1,.44.44,0,0,1,.17.6l0,.07-.24.38a.45.45,0,0,1-.59.24.21.21,0,0,1-.1-.06,5.64,5.64,0,0,0-2.6-.91c-2.1,0-2.74,1.35-2.74,2.18,0,1.33,1,2.1,2.68,2.79,2.68,1.09,4.4,2.1,4.4,4.4,0,2.06-2,3.57-4.28,3.57A6.43,6.43,0,0,1,107,49a.44.44,0,0,1-.21-.58A.33.33,0,0,1,106.86,48.36Z"
              />
              <path
                class="cls-1"
                d="M117.42,48.36l.3-.38c.2-.26.42-.42.69-.18a5.42,5.42,0,0,0,3.35,1.51,2.38,2.38,0,0,0,2.64-2.11s0-.06,0-.09c0-1.37-1.19-2.18-3.47-3.13s-3.49-1.84-3.49-4.1c0-1.35,1.07-3.53,4.22-3.53a6.57,6.57,0,0,1,3.39,1,.44.44,0,0,1,.17.6l0,.07-.24.38a.45.45,0,0,1-.59.24.21.21,0,0,1-.1-.06,5.64,5.64,0,0,0-2.6-.91c-2.1,0-2.74,1.35-2.74,2.18,0,1.33,1,2.1,2.68,2.79,2.68,1.09,4.4,2.1,4.4,4.4,0,2.06-2,3.57-4.28,3.57A6.45,6.45,0,0,1,117.53,49a.43.43,0,0,1-.13-.6A.05.05,0,0,1,117.42,48.36Z"
              />
              <path
                class="cls-1"
                d="M135.23,37.9h-3.45a.38.38,0,0,1-.38-.38h0V37a.38.38,0,0,1,.38-.38h8.4a.38.38,0,0,1,.38.38h0v.57a.38.38,0,0,1-.38.38h-3.45V50.11a.39.39,0,0,1-.38.38h-.75a.38.38,0,0,1-.38-.38Z"
              />
              <path
                class="cls-1"
                d="M142.74,37a.39.39,0,0,1,.38-.38h.77a.38.38,0,0,1,.38.38h0v5.79h8V37a.38.38,0,0,1,.38-.38h.77a.4.4,0,0,1,.38.38V50.11a.39.39,0,0,1-.38.38h-.77a.38.38,0,0,1-.38-.38h0v-6h-8v6a.38.38,0,0,1-.38.38h-.77a.38.38,0,0,1-.38-.38Z"
              />
              <path
                class="cls-1"
                d="M157.88,37a.38.38,0,0,1,.38-.38h7.81a.38.38,0,0,1,.38.38h0v.57a.38.38,0,0,1-.38.38h-6.66v4.84h5.69a.4.4,0,0,1,.38.38v.58a.38.38,0,0,1-.38.38h-5.69v5h6.66a.38.38,0,0,1,.38.38h0v.62a.38.38,0,0,1-.38.38h-7.81a.38.38,0,0,1-.38-.38h0Z"
              />
              <path
                class="cls-1"
                d="M172.72,37.05a.34.34,0,0,1,.19-.45.47.47,0,0,1,.17,0h.83a.44.44,0,0,1,.36.28L177,47.11h.08l3.31-10.53a.34.34,0,0,1,.34-.22h.36a.38.38,0,0,1,.34.22l3.37,10.53h.08l2.7-10.27a.43.43,0,0,1,.36-.28h.83a.35.35,0,0,1,.38.32.3.3,0,0,1,0,.16l-3.75,13.32a.37.37,0,0,1-.36.28h-.32a.38.38,0,0,1-.34-.22L180.94,39.7h-.1l-3.37,10.72a.4.4,0,0,1-.34.22h-.32a.36.36,0,0,1-.36-.28Z"
              />
              <path
                class="cls-1"
                d="M189.51,49.93l6-13.34a.38.38,0,0,1,.34-.22h.2a.38.38,0,0,1,.34.22l6,13.34a.36.36,0,0,1-.13.48.32.32,0,0,1-.21,0h-.79a.35.35,0,0,1-.34-.22L199.45,47h-7L191,50.23a.36.36,0,0,1-.34.22h-.79a.34.34,0,0,1-.41-.27A.36.36,0,0,1,189.51,49.93Zm9.42-4.2c-1-2.16-1.92-4.34-2.89-6.5h-.16L193,45.73Z"
              />
              <path
                class="cls-1"
                d="M205.74,37.9h-3.45a.38.38,0,0,1-.38-.38h0V37a.38.38,0,0,1,.38-.38h8.4a.38.38,0,0,1,.38.38h0v.57a.38.38,0,0,1-.38.38h-3.45V50.11a.39.39,0,0,1-.38.38h-.75a.38.38,0,0,1-.38-.38Z"
              />
              <path
                class="cls-1"
                d="M213.25,37a.38.38,0,0,1,.38-.38h7.81a.38.38,0,0,1,.38.38h0v.57a.38.38,0,0,1-.38.38h-6.66v4.84h5.69a.4.4,0,0,1,.38.38v.58a.38.38,0,0,1-.38.38h-5.69v5h6.66a.38.38,0,0,1,.38.38h0v.62a.38.38,0,0,1-.38.38h-7.81a.38.38,0,0,1-.38-.38h0Z"
              />
              <path
                class="cls-1"
                d="M225,37a.38.38,0,0,1,.38-.38h5.13a4.26,4.26,0,0,1,4.32,4.2v0a4.42,4.42,0,0,1-2.93,4l2.72,5a.4.4,0,0,1-.12.53.34.34,0,0,1-.22,0h-1a.33.33,0,0,1-.32-.18l-2.64-5.25h-3.79v5.17a.39.39,0,0,1-.38.38h-.77a.38.38,0,0,1-.38-.38h0Zm5.41,6.76a2.85,2.85,0,0,0,0-5.69h-3.85v5.69Z"
              />
              <path
                class="cls-1"
                d="M63,20.11V33.25a.39.39,0,0,1-.38.38H60.29a.37.37,0,0,1-.37-.38V28H54.24v5.22a.38.38,0,0,1-.38.38H51.51a.38.38,0,0,1-.38-.38V20.11a.4.4,0,0,1,.38-.37h2.35a.38.38,0,0,1,.38.37v5h5.67v-5a.37.37,0,0,1,.37-.37h2.37a.4.4,0,0,1,.36.37m14.5,5.14v8.19a.39.39,0,0,1-.38.38H74.7a.37.37,0,0,1-.38-.37V30.53H69.41v2.92a.38.38,0,0,1-.38.38H66.59a.38.38,0,0,1-.38-.37v-8.2a5.65,5.65,0,0,1,11.3,0m-3.19,2.43V25.41a2.46,2.46,0,1,0-4.92,0h0v2.28Zm17.9-7.94H89.85a.38.38,0,0,0-.38.37v7h0l-7.91-7.61H81a.37.37,0,0,0-.38.35V33.2a.38.38,0,0,0,.37.38h2.36a.38.38,0,0,0,.38-.38V25.85h0l7.83,7.87a.52.52,0,0,0,.26.1h.32a.38.38,0,0,0,.38-.36V20.11a.4.4,0,0,0-.38-.37m15.92,6.92a7,7,0,0,1-7,7H96.36a.36.36,0,0,1-.36-.38h0V20.11a.37.37,0,0,1,.36-.37h4.83a7,7,0,0,1,7,6.92m-3.29,0a3.85,3.85,0,0,0-3.7-4H99.13v8H101a3.85,3.85,0,0,0,3.87-3.84v-.16M115,25.2c-1.57-.64-2.16-1.19-2.16-1.92a1.15,1.15,0,0,1,1.25-1,6.36,6.36,0,0,1,2.83,1.19c.17.14.54,0,.67-.26l1-1.53a.5.5,0,0,0-.12-.63,7.46,7.46,0,0,0-4.24-1.49c-3,0-4.58,2-4.58,3.94,0,2.38,2.14,3.71,3.84,4.38,1.35.54,2,1.17,2,2a1.25,1.25,0,0,1-1.25,1.24h-.07A5.72,5.72,0,0,1,111.37,30a.51.51,0,0,0-.68.12l-.93,1.64c-.16.28-.1.36.1.56a6.27,6.27,0,0,0,4.46,1.53,4.34,4.34,0,0,0,4.67-4,.31.31,0,0,0,0-.1c0-2.7-2.46-3.91-4-4.52"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
